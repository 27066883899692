<!-- eslint-disable no-unused-vars -->
<template>
  <div class="col-12 col-sm-12 col-md-4 wrapper d-flex flex-column">
    <div class="d-flex">
      <h1 class="title mt-0 fs-b fs-2500 color-pr">
        Certification du statut de dirigeant
      </h1>
    </div>

    <div class="mt-3">
      <p>
        Vous vous êtes déclarés comme dirigeant lors de votre inscription.
        Veuillez saisir le(s) SIREN pour le(s)quel(s) vous êtes dirigeant. Pour
        chaque SIREN, vous devez certifier votre statut afin d’obtenir le rôle
        Administrateur Général d’Entreprise - AGE
        <Info
          cssClass="ri-information-line ml-2 text-dark h a-info ri-lg"
          href="#"
          :data="ageInfo"
        />.<br />
        Cette action peut aussi être effectuée ultérieurement en vous rendant
        dans la gestion des rôles.
      </p>
    </div>

    <div class="container-flow">
      <div
        v-for="(sirenData, index) in sirensAjoutee"
        :key="sirenData.siren"
        class="saisie-siren"
      >
        <div class="bloc-siren">
          <div class="siren-num">SIREN {{ index + 1 }}</div>
          <div class="siren-input">
            <span>{{ sirenData.siren }}</span>
          </div>
          <div class="">
            <span v-if="sirenData.isAge" class="certification-reussi"
              ><span class="icon-rond"><i class="ri-check-line"></i></span>
              Certification réussie, rôle AGE obtenu</span
            >
            <span v-if="sirenData.isNotDeclare"
              ><button
                type="button"
                class="fr-btn"
                @click="
                  toAlternativeProConnect(sirenData);
                  tagCertification('to_alternative_proconnect');
                "
                title="Alternative ProConnect"
              >
                Rôle non obtenu, Alternative ProConnect
              </button></span
            >
          </div>
        </div>
        <div class="bloc-siren">
          <div class="entreprise-bloc">
            <div class="icon">
              <span class="ri-building-fill color-pr" />
            </div>
            <div class="entreprise-info fs-750">
              <div class="fr-grid-row">
                <div class="fr-col-12">
                  <div class="text-entreprise">Entreprise</div>
                </div>
                <div class="rs-width">
                  <div class="fs-b">{{ sirenData.rs }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="saisie-siren" v-if="!ajouterSiren">
        <button
          type="button"
          class="fr-btn"
          @click="
            nouveauSiren();
            tagCertification('ajout_siren');
          "
          title="Ajouter un SIREN"
        >
          Ajouter un SIREN
        </button>
      </div>
      <div class="saisie-siren" v-else>
        <div class="bloc-siren">
          <div class="siren-num">SIREN {{ this.sirensAjoutee.length + 1 }}</div>
          <div class="siren-input">
            <label class="text-info mr-5 mb-2" for="siren">9 chiffres</label>
            <input
              id="siren"
              :class="`fr-input ${
                error || errorLengthNumber ? ' fr-input--error' : ''
              }
              `"
              type="text"
              :name="`siren-edit`"
              v-model="sirenInput"
              @input="validateSiren(sirenEdit)"
              maxlength="11"
              v-maska
              data-maska="### ### ###"
            />
            <p class="message-error d-flex align-items-center" v-if="error">
              <i class="ri-error-warning-line" />
              {{ sirenEdit.errorMessageExistingNumber }}
            </p>
            <p
              class="message-error d-flex align-items-center"
              v-else-if="errorLengthNumber"
            >
              <i class="ri-error-warning-line" />
              {{ sirenEdit.errorMessageLengthNumber }}
            </p>
          </div>
          <div class="certification-button">
            <button
              type="button"
              class="fr-btn"
              @click="
                certifierStatut(sirenEdit);
                tagCertification('certifier_statut');
              "
              title="Certifier mon statut"
            >
              Certifier mon statut
            </button>
          </div>
        </div>
        <div class="bloc-siren">
          <div class="entreprise-bloc">
            <div class="icon">
              <span class="ri-building-fill color-pr" />
            </div>
            <div class="entreprise-info fs-750">
              <div class="fr-grid-row">
                <div class="fr-col-12">
                  <div class="text-entreprise">Entreprise</div>
                </div>
                <div class="rs-width">
                  <div class="fs-b">{{ sirenEdit.rs }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="certification-button">
        <button
          type="button"
          class="fr-btn"
          @click="
            terminer();
            tagCertification('terminer');
          "
          title="Terminer"
        >
          Terminer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { proconnectErrorMessage, removeSpaces } from '../utils/methodsUtils';
import SkipLinkMixin from '../mixins/skip-link-mixin';

import Info from '../shared/Info';
import { CERTIFICATION_DIRIGEANT, SOURCE_PAGE_CDS } from '../constantes';
import { habilitationService } from '../services/HabilitationService';
import { sendTag } from '../utils/methodsUtils';

export default {
  name: 'RoleAGE',
  mixins: [SkipLinkMixin],
  components: {
    Info
  },
  data() {
    return {
      ageInfo: `Le rôle Administrateur Général de l’Entreprise – AGE – permet d’accéder
        et d’administrer l’ensemble des services disponibles dans le portail au
        regard des obligations de votre entreprise. Ce rôle s’obtient en
        certifiant votre statut de dirigeant.`,
      nonDirigeant: '',
      nonDirigeantError: false,
      sirensAjoutee: [],
      sirenEdit: {
        siren: '',
        rs: '',
        error: false,
        errorMessageExistingNumber: '',
        errorMessageLengthNumber: ''
      },
      sirenInput: '',
      ajouterSiren: false,
      error: false,
      errorLengthNumber: false,
      roleData: {}
    };
  },
  watch: {
    sirenInput(newValue) {
      if (newValue && removeSpaces(newValue).length === 9) {
        this.rechercherRaisonSociale(newValue);
      }
    }
  },
  mounted() {
    this.roleData =
      this.getRouteData && this.getRouteData.roleData
        ? this.getRouteData.roleData
        : {};
    const listSiren = JSON.parse(localStorage.getItem('sirensAjoutee'));
    if (listSiren) {
      this.sirensAjoutee = JSON.parse(localStorage.getItem('sirensAjoutee'));
    }
    if (this.sirensAjoutee && this.sirensAjoutee.length === 0) {
      this.ajouterSiren = true;
    }
  },
  methods: {
    ...mapActions(['showPopup', 'setContent', 'setRouteData']),
    tagCertification(value) {
      sendTag('Certification_Statut_Dirigeant', value, '', '');
    },
    toAlternativeProConnect(sirenData) {
      const routeData = { source: SOURCE_PAGE_CDS, roleData: sirenData };
      this.setRouteDataAndPush(
        routeData,
        'Confirmation de la demande de rôle AGE'
      );
    },
    nouveauSiren() {
      this.ajouterSiren = true;
    },
    terminer() {
      this.$router.push({ name: 'Gestion des rattachements' });
    },
    rechercherRaisonSociale(sirenUpdate) {
      this.sirenEdit.siren = sirenUpdate;
      if (this.validateSiren(this.sirenEdit)) {
        let paramSiren = [];
        paramSiren.push(removeSpaces(this.sirenEdit.siren));
        habilitationService
          .getRsBySiren(paramSiren)
          .then((result) => {
            const rs =
              result.data && result.data.sirens && result.data.sirens.length
                ? result.data.sirens[0].rs
                : '';

            this.sirenEdit.rs = rs;
          })
          .catch((err) => {
            this.sirenEdit.rs = '';
            console.error(err);
          });
      }
    },

    certifierStatut(sirenEdit) {
      sirenEdit.siren = this.sirenInput;
      if (this.validateSiren(sirenEdit)) {
        const html = `<p class="fs-875 text-center">Vous allez être redirigé vers ProConnect pour certifier votre statut de dirigeant. Souhaitez-vous continuer ?</p>`;
        this.setContent({ html, action: 'confirm' });
        this.showPopup();
        this.emitter.once('popup', (payload) => {
          if (payload === 'oui') {
            this.demandeRoleViaProconnect(
              sirenEdit.siren.split(' ').join(''),
              sirenEdit.rs
            );
            this.sirenInput = '';
          }
        });
      }
    },
    demandeRoleViaProconnect(siren, rs) {
      let html = '';

      const sirenObject = {
        siren: siren,
        rs: rs,
        isAge: false,
        isNotDeclare: false
      };
      this.sirensAjoutee.push(sirenObject);
      localStorage.setItem('sirensAjoutee', JSON.stringify(this.sirensAjoutee));

      this.$proconnectService
        .createUpdateDemande(siren)
        .then((result) => {
          if (result.status === 201) {
            localStorage.setItem('action', CERTIFICATION_DIRIGEANT);
            localStorage.setItem('proconnectSiren', siren);
            window.location.href = result.data.callBackURL;
          }
        })
        .catch((err) => {
          if (err?.response?.data?.errorCode === '10') {
            let upd_siren = this.sirensAjoutee.findIndex(
              (obj) => obj.siren === siren
            );
            this.sirensAjoutee[upd_siren].isNotDeclare = true;
            localStorage.setItem(
              'sirensAjoutee',
              JSON.stringify(this.sirensAjoutee)
            );
          }
          const errorResponse = proconnectErrorMessage(err?.response?.data);
          html = `<p class="fs-875 text-center">${errorResponse.message}</p>`;
          this.setContent({ html, action: 'info' });
          this.showPopup();
          this.emitter.once('popup', (payload) => {
            if (payload === 'ok' || payload === 'hide') {
              if (errorResponse.rectif === true) {
                this.showModal();
              }
            }
          });
        });
    },
    validateSiren(sirenEdit) {
      this.error = this.existingNumber;
      this.errorLengthNumber = this.numberLength < 9 && this.numberLength > 0;
      if (this.error) {
        sirenEdit.error = true;
        sirenEdit.errorMessageExistingNumber = 'SIREN déjà ajouté.';
      } else if (this.errorLengthNumber) {
        sirenEdit.error = true;
        sirenEdit.errorMessageLengthNumber =
          'Veuillez saisir un SIREN avec 9 chiffres.';
        return false;
      } else {
        sirenEdit.error = false;
        sirenEdit.errorMessageExistingNumber = '';
        sirenEdit.errorMessageLengthNumber = '';
        return true;
      }
    },

    setRouteDataAndPush(routeData, routeName) {
      this.setRouteData(routeData)
        .then(() => {
          this.$router.push({
            name: routeName
          });
        })
        .catch((error) => console.error(error));
    }
  },
  computed: {
    ...mapGetters(['getRouteData']),
    existingNumber() {
      const siren = this.sirenInput ? this.sirenInput.split(' ').join('') : '';
      const objIndex = this.sirensAjoutee.findIndex(
        (obj) => obj.siren === siren
      );
      if (siren && siren.length === 9) {
        if (objIndex !== -1) {
          return true;
        } else {
          return false;
        }
      } else return false;
    },
    numberLength() {
      return this.sirenInput ? this.sirenInput.split(' ').join('').length : 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.go-gdr-link {
  border-bottom: $base-color solid 0.8px;
}
</style>

<style lang="scss">
.certification-reussi {
  width: 100%;
  display: block ruby;
}
.entreprise-bloc {
  display: flex;
  padding-left: 5rem;
}
.entreprise-info {
  display: grid;
  justify-self: left;
}
.icon {
  margin-right: 10px;
}

.siren-num {
  text-align: center;
  padding-right: 1rem;
}
.siren-input {
  padding-right: 1rem;
}
.certification-button {
  padding-top: 2.5rem;
}

.bloc-siren {
  display: flex;
}
#message-radio {
  margin-left: 2rem;
  width: 78%;
}

.fr-form-group {
  margin-bottom: 2rem;
  margin-left: 5rem;
}
.non-dirigeant .fr-radio-group {
  justify-content: flex-start;
  display: flex;
}
fieldset:not(.fieldset-visually-hidden) {
  border: none !important;
}
.buttons {
  justify-content: center;
  display: flex;
}
.ri-information-line {
  padding-top: 0.5rem;
}
.logo-proconnect {
  justify-content: center;
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}

.bloc-proconnect {
  justify-content: center;
  display: block;
  width: 84%;
  padding-top: 1rem;
  padding-left: 3rem;
}

.text-intro {
  text-align: center;
}
.container-flow {
  width: 82%;
  margin-left: 5rem !important;
}

.fr-link {
  cursor: pointer !important;
}

h1 {
  color: $base-color;
}

.title {
  float: left;
}

.col {
  flex: 1 0 0%;
}

img,
svg {
  vertical-align: middle;
}

.etape1-checkbox {
  display: inline-grid;
  width: 22.34rem;
  padding: 20px 35px;
  height: 96px;
  text-align: center;
  background-color: #fff;
  border: solid 2px #dddddd;
  margin-right: 2rem;
  justify-items: center;
}

.fr-radio-group input[type='radio'] {
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.etape1-checkbox {
  display: inline-grid;
  width: 22.34rem;
  padding: 20px 35px;
  height: 96px;
  text-align: center;
  background-color: #fff;
  border: solid 2px #dddddd;
  margin-right: 2rem;
  justify-items: center;
}

.etape1-checkbox:hover {
  box-shadow: #dddddd 0px 6px 30px 0px !important;
  transform: translateY(-1px);
  cursor: pointer;
}

.row-etape1 {
  display: block !important;
  --bs-gutter-x: 2.5rem;
  border-bottom: solid #ffffff 23px;
  width: max-content;
  margin: auto;
  margin-left: 5rem;
  margin-top: 2rem;
}

.ri-check-line {
  padding-top: 1%;
  padding-left: 6%;
  color: white;
}

.ri-error-warning-line {
  margin-top: 0.2rem;
  margin-right: 0.5rem;
}

.icon-rond {
  display: block;
  margin-top: 1%;
  background: #16a757;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  color: white;
}

.fr-input {
  box-shadow: inset 0 -2px 0 0 #000091;
}

.fr-select {
  box-shadow: inset 0 -2px 0 0 #000091;
}

.etape1-text {
  text-align: center !important;
  margin-bottom: 1rem !important;
  margin-right: 3rem !important;
}

.fr-label::before {
  display: none;
}

.fr-link {
  cursor: pointer !important;
}

.label-dirigeant,
.label-non-dirigeant {
  margin-top: 0.45rem !important;
}

a {
  color: black;
  background-image: none;
}

.ri-eye-line::before {
  margin-right: 0.3rem;
}

.ri-add-line::before {
  margin-right: 0.5rem;
  font-size: 1.5em;
}

.fr-input--error {
  box-shadow: inset 0 -2px 0 0 var(--border-plain-error) !important;
}

.certification-button {
  justify-content: flex-end;
  display: flex;
  padding-top: 2rem;
}

.text-info {
  color: #6a6a6a !important;
  font-size: 13px;
}

.saisie-siren {
  padding-bottom: 2rem;
}

@media only screen and (max-width: 760px) {
  .title {
    font-size: 2rem;
  }

  .fr-input {
    padding-top: 0 !important;
  }

  .fr-search-bar,
  .fr-search-type {
    width: auto;
    margin: auto;
    width: 100%;
  }

  .fr-fieldset__content {
    display: block;
  }

  .fr-radio-group input[type='radio'] + label {
    display: block;
    margin-left: 8rem;
  }
  .container-flow {
    width: 100%;
    margin-left: 0rem !important;
  }

  .certification-button {
    padding-bottom: 2rem;
  }
}
</style>
